<template>
    <div class="drawingvideo-wrapper">
        <div class="containers" >
          <div class="closeVideo" @click="closeDrawingVideo"></div>
          <div class="info-wrapper">
            <h1> 福彩快8六合彩 </h1>
            <h2>第<span>{{periodNo}}</span>期开奖号码</h2>
          </div>
          <h3>每日21:30:00开奖</h3>
          <div class="draw-info">
              <div class="ballWrapper">
                <div :class="`kl8lhc ball ball-${parseInt(num)}`"
                     v-for="(num,index) in drawNumber.slice(0,6)"
                     :key="index">{{num | padLeft(2)}}</div>
                <div class="add">+</div>

                <div :class="`kl8lhc ball ball-${parseInt(drawNumber.slice(6,7))}`">
                  {{parseInt(drawNumber.slice(6,7)) | padLeft(2)}}
                </div>
              </div>
            <div  class="textWrapper mappingBall_sx">
              <div v-for="(sx,index) in showAnimal.slice(0,6)"  class="text" :key="index+'-sx'">
                {{sx}}
              </div>
              <div class="add"></div>
              <div class="text">{{showAnimal[6]}}</div>
            </div>


            <div class="textWrapper mappingBall_wx">
              <div v-for="(wx,index) in showFiveElements.slice(0,6)"  class="text" :key="index+'-wx'" >
                {{wx}}
              </div>
              <div class="add"></div>
              <div class="text">{{showFiveElements[6]}}</div>
            </div>
            </div>


            <!-- Loading the player ... -->
            <video-player
                id="myplayer" class="video-wrapper vjs-default-skin vjs-big-play-centered"
                ref="videoPlayer"
                :playsinline="true"
                :options="playerOptions"
                @statechanged="playerStateChanged($event)"
                @play="onPlayerPlay($event)">
            </video-player>

        </div>
    </div>
</template>
<script type="text/javascript">
import 'video.js/dist/video-js.css'
import { videoPlayer } from 'vue-video-player'
export default {
    components: {
        videoPlayer,
    },
    props: ["year", "month", "date", "periodNo", "drawNumber", "showAnimal", "showFiveElements"],
    data: function () {
        return {
            playerOptions: {
              autoplay: true, // 如果为true,浏览器准备好时开始回放。
                muted: false, // 默认情况下将会消除任何音频。
                loop: false, // 是否视频一结束就重新开始。
                preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: 'zh-CN',
                fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [{type: "video/mp4"}],
                notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                controlBar: {
                    timeDivider: true, // 当前时间和持续时间的分隔符
                    durationDisplay: true, // 显示持续时间
                    remainingTimeDisplay: false, // 是否显示剩余时间功能
                    fullscreenToggle: true // 是否显示全屏按钮
                }
            }
        };
    },
    mounted() {
      setTimeout(() => {
        this.updateMedia();
      }, 20)
    },
    methods: {
      onPlayerPlay() {
          this.videoIsPlay = true;
      },
      updateMedia(){
        if (parseInt(this.year+''+this.month+''+this.date) < 20240522){
          this.playerOptions.sources.push({
            src:`https://video.cwl.gov.cn/vod_storage/vol1/${this.year}/${this.month}/${this.date}/${this.year}${this.month}${this.date}0935t/${this.year}${this.month}${this.date}0935t.mp4`
          })
        }else{
          this.playerOptions.sources.push({
            src:`https://video.cwl.gov.cn/vod_storage/vol1/${this.year}/${this.month}/${this.date}/kl8/kl8${this.year}${this.month}${this.date}.mp4`
          })
        }
      },
      playerStateChanged(state) {},
      closeDrawingVideo() {
          this.$emit('closeDrawingVideo');
      }
    },
};
</script>
<style lang="scss" scoped>
.drawingvideo-wrapper:after {
  position:absolute;
  height:100%;
  width:100%;
  top:0;
  left:0;
  background-color: rgba(0, 0, 0, .6);
  z-index: 6;
  content:'';
}
.drawingvideo-wrapper {
  position:fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  display: flex;
  align-items:center;
  justify-content:center;
  flex-direction:column;
  padding:5px;
  .containers {
    position:relative;
    width: 100%;
    z-index: 101;
    display:flex;
    justify-content: center;
    align-items:center;
    flex-direction:column;
    background:#fff;
    padding:10px;
    border-radius:10px;
    @media(min-width:992px){
      width:1070px;
      flex-direction:row;
      flex-wrap:wrap;
      padding:30px 15px 15px;
      align-items:flex-start;
    }
  }
  .video-wrapper{width:100%;height:0;position:relative;padding-bottom:56%;}
  .video-wrapper video{width:100%;height:100%;position:absolute;}
  .closeVideo{
    position:absolute;
    top:10px;
    right:10px;
    width: 20px;
    height: 20px;
    background-image: url('~@/img/ico_close_gray.png');
    background-position: center;
    background-repeat: no-repeat;
    z-index: 99;
    cursor: pointer;
  }
}
h1{font-size:22px;color:#0f2957;margin-bottom:15px;}
h2{
  font-size:18px;
  color:#0f2957;
  margin-bottom:10px;
  span{color:#c19971}
}
h3{font-size:14px;color:#999;margin:10px auto;}


.ballWrapper, .textWrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  width:95%;
  margin:10px auto 10px;
}
.draw-info{width:100%}
@media(min-width:992px){
  h1{margin-bottom:20px;}
  h3{margin-top:0;margin-left:100px;}
  .draw-info{width:400px;margin-top:0;margin-right:60px;}
  .ballWrapper{margin-top:0}
}
.ball {
  width: 32px!important;
  height: 32px!important;
  line-height: 32px!important;
  margin:0 2px;
  font-size: 18px!important;
  text-align:center;
  @media(min-width:380px){
    width: 40px!important;
    height: 40px!important;
    line-height: 40px!important;
    margin:0 4px;
    font-size: 20px!important;
  }
}
.text{
  width: 32px;
  margin:0 2px;
  font-size: 14px;
  text-align:center;
  @media(min-width:380px){
    width: 40px;
    margin:0 4px;
    font-size: 16px;
  }
  @media(min-width:992px){
    font-size: 14px;
  }
}
.add{
  margin:0 8px;
  width:6px;
  @media(min-width:380px){
    margin:0 12px;
    width:10px;
  }
  @media(min-width:992px){
    font-size: 14px;
  }
}
.mappingBall_wx .text{color:#ff0000;}
@media(min-width:992px){
  .mappingBall_wx .text{color: #000;margin-bottom:10px;}
}
</style>
